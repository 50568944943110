@use "sass:math";
@import 'helpers/colours';
@import 'helpers/vars';
@import '../../../styles/colors/colors.scss';

// Breakpoints
$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;

.breadcrumb {
  font-size: 12px;
}

.learn__page {
  font-family: $brand-font-body;

  .h1 {
    color: $grey;
    font-size: 48px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 50px;
  }
}

.learn__home-page {
  padding-top: 120px;
  margin-bottom: 120px;

  .learn__wrapper--paragraph {
    margin-bottom: $x-large-padding;
  }
}

.learn__wrapper--paragraph {
  color: $grey;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 40px 0;
}

.learn__carousel {
  margin: 0 25%;
  text-align: center;
}

.learn__introduction-page {
  margin-bottom: 120px;
  padding-top: 80px;
}

.learn__carousel--paragraph {
  font-size: 19px;
  font-weight: normal;
  line-height: 29px;
}

.learn__introduction {
  margin: 0 percentage(math.div(1, 4)) 60px;
}

.learn__categories-content {
  margin-left: 0;
  margin-right: 0;

  h2 {
    font-size: 48px;
  }

  @include tablet {
    margin-left: 1.5 * $standard-padding;
    margin-right: 1.5 * $standard-padding;
  }
}

.learn__export-academy-logo {
  width: 83px;

  @include tablet {
    width: 115px;
  }

  &.small {
    width: 71px;
  }
}

.learn__categories-logo {
  margin-top: -$large-padding;
}

.learn__category-header {
  padding-top: 80px;
  margin-bottom: 80px;
}

.learn__step-block {
  background: url('/static/img/circle-check-icon.png') no-repeat
    calc(50% + 15px) 0;
  padding-top: 88px;
  text-align: center;
}

.learn__step-title {
  color: $grey;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: $large-padding;
}

.learn__category {
  background-color: $white;
  margin-bottom: $x-large-padding;
  position: relative;

  @include tablet {
    padding-left: $x-large-padding;
    min-height: 360px;
    margin-bottom: 40px;
  }
}

.learn__category-content {
  padding: $large-padding large-padding 0;

  @include tablet {
    padding: 2 * $large-padding $large-padding 2 * $large-padding 25px;
  }

  .button {
    display: none;

    @include tablet {
      display: inline-flex;
    }
  }
}

.learn__category-content--progress-bar {
  padding-top: 80px;

  @include tablet {
    padding-top: 2 * $large-padding;
  }
}

.learn__category-title {
  @include tablet {
    margin-top: 13px;
    line-height: 48px;
  }
}

.learn__category-progress {
  @extend .progress-bar;
}

.learn__category-progress-container {
  @include tablet {
    position: static;
  }
}

.learn__category-link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  &:active:focus {
    background: revert;
  }
}

.learn__category-details {
  margin: 0 0 $large-padding;

  @include tablet {
    margin: 0 0 $x-large-padding;
  }

  p {
    margin-top: 16px;
    font-size: 20px;
  }
}

.learn__category-entities {
  @extend .body-m-b;
  padding: 8px 17px 7px;
  color: $white;
  display: inline-block;
  white-space: nowrap;
  top: 35px;
  right: 0;
  margin-left: 20px;

  @include tablet {
    font-family: $brand-font-body;
    padding: 13px 17px 9px;
    margin-left: 0;
    position: absolute;
    font-weight: normal;
    font-size: 19px;
  }
}

.learn__category-image {
  border-bottom-right-radius: 10px;
  max-width: 100%;
  height: auto;
  width: auto;
  margin-top: 20px;

  @include tablet {
    position: absolute;
    margin-top: 0;
    max-width: 50%;
    max-height: 250px;
    right: 0;
    bottom: 0;
  }
}

.learn__single-category-header-wrapper {
  padding-top: 40px;
  position: relative;

  .learn__category-entities {
    margin-left: 0;
  }
}

.learn__single-category-header-wrapper--progress-bar-top {
  padding-top: 80px;

  @include tablet {
    padding-top: 40px;
  }
}

.learn__single-category-header-content {
  margin-bottom: 10px;

  @include tablet {
    margin: 38px 0 30px 70px;
  }
}

.learn__single-category-title.h1 {
  margin-bottom: 16px;
}

.learn__single-category-header {
  background-color: $white;
}

.learn__single-category-header-details p {
  @extend .body-m;
  color: $blue-deep-80;
  margin-top: 15px;

  @include tablet {
    font-size: 20px;
  }
}

.learn__single-category-image {
  max-width: 100%;
  height: auto;
}

.learn__topics-list {
  margin: 40px 0 0;
}

.learn__topic-item-title {
  margin-right: 20px;
  padding-bottom: 20px;
}

.learn__topic-item-count {
  margin-bottom: 10px;

  @include tablet {
    margin-bottom: 0;
  }
}

.learn__topic-item {
  margin-bottom: 50px;
  padding-left: 4px;
}

.learn__topic-duration {
  color: $grey;
  font-size: 16px;
  font-weight: bold;
}

.learn__lessons-list {
  margin: 0;
  position: relative;
}

.learn__lesson-item {
  background-color: $white;
  border-bottom: 0;
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  & a:hover {
    box-shadow: none !important;
  }
  &:not(:last-of-type) {
    padding-bottom: 2px;
  }

  &--placeholder {
    background-color: rgba($white, 0.6);

    span,
    button {
      opacity: 0.6;
    }

    button {
      @include tablet {
        float: right;
      }
    }
  }
}

.learn__lesson-item-link {
  color: $blue-deep-80;
  display: block;
  padding: 18px 20px 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include tablet {
    padding: 22px 20px 20px 21px;
  }

  &:visited {
    color: $blue-deep-80;
  }

  .button {
    @include tablet {
      position: relative;
      top: -4px;
    }
  }
}

.learn__lesson-item-link-text {
  display: block;
  margin-bottom: 10px;
  flex: 1;

  &:hover {
    color: $color-gov-link;
  }

  @include tablet {
    display: inline-block;
    margin-bottom: 0;
  }
}

.learn__lesson-item-link--completed {
  @include tablet {
    float: right;
  }
}

.learn__topic-item-read-time {
  display: inline-block;

  @include tablet {
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: 22px;
  }
}

.learn__back-link {
  left: 0px;
  top: 0px;
  position: absolute;
  z-index: 1;

  &--bottom {
    top: auto;
    bottom: 20px;
  }
}

.learn__beta-banner {
  flex-direction: row;

  > i {
    width: 35px;

    &:before {
      font-size: 26px;
    }
  }

  .g-panel {
    padding: 0 0 0 25px;

    h3 {
      margin-top: -10px;
    }

    @include tablet {
      padding: 10px 0 10px 30px;

      h3 {
        margin-top: 0;
      }
    }
  }

  @include tablet {
    > i {
      width: 70px;

      &:before {
        font-size: 40px;
      }
    }
  }
}

#lesson-page {
  details {
    summary {
      font-weight: bold;
      color: #333;

      h3 {
        display: inline;
      }
    }
  }

  li.great-mvp-list-arrow {
    list-style-type: disclosure-closed;
    margin-left: 20px;
    padding-left: 5px;
  }

  nav {
    background: $white;

    .great-mvp-pill-button {
      margin-left: 5px;
    }
  }

  aside {
    background: $white;
    border-right: 1px solid $light-grey;
    height: 100%;
    top: 0;

    h3 {
      padding-top: 20px;
      padding-bottom: 0;

      a.topic-link {
        text-decoration: none;
      }
    }

    a {
      color: $grey;
      font-size: 16px;
    }
  }

  .lesson-next-area {
    display: inline-block;

    h5 {
      color: #919191;
      font-size: 16px;
      font-weight: bold;
    }

    .lesson-next-link {
      color: #3c3c3c;
      font-size: 36px;
      font-weight: bold;
    }

    .lesson-back-to-learning {
      color: #3c3c3c;
      font-size: 19px;
      font-weight: bold;
      display: block;
    }
  }
}

.lesson-page {
  .rich-text {
    h2,
    h3,
    h4 {
      margin-bottom: 18px;
      padding: 0;

      @include tablet {
        margin-bottom: 30px;
      }
    }

    h2 {
      @extend .h-m;
    }

    h3 {
      @extend .h-s;
    }

    h4 {
      @extend .h-xs;
    }

    b > i {
      font-weight: bold;
    }

    p {
      margin-top: 18px;
      margin-bottom: 18px;

      @include tablet {
        margin-top: 22px;
        margin-bottom: 22px;
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    ol {
      @extend .list-number;
      margin-left: 0;

      li {
        list-style-position: inside;
        margin-bottom: 11px;
        padding-left: 0;
        position: static;
      }
    }

    hr {
      height: 1px;
      margin: 0;
      border: 0;
    }

    a.link {
      text-decoration: underline;
      font-size: $font-size-p;
    }

    .choose-dont-choose {
      h3 {
        @extend .h-xs;
        margin-bottom: 10px;

        @include tablet {
          padding: 0;
        }
      }

      span[role='img'] {
        margin-bottom: 0;

        @include tablet {
          margin-bottom: 20px;
        }
      }

      .g-panel {
        &:before {
          display: none;

          @include tablet {
            display: block;
          }
        }

        h3 {
          margin-bottom: 0;

          @include tablet {
            margin-bottom: 10px;
          }
        }

        p {
          margin: 0;

          @include tablet {
            margin-bottom: 18px;
            margin-top: 18px;
          }
        }
      }
    }

    .pros-and-cons {
      h3 {
        @extend .h-s;
        padding-bottom: 15px;
        padding-top: 0;
        margin-bottom: 0;
      }

      ul {
        @extend .list-bullet;
        margin-bottom: 0;

        li {
          @extend .body-l;
          position: relative;
          margin-bottom: 17px;
          padding-left: 17px;

          &:before {
            width: 4px;
            height: 4px;
          }
        }
      }

      &__pros {
        margin-bottom: 10px;

        @include desktop {
          margin-right: -10px;
          margin-bottom: 0;
        }
      }

      &__cons {
        @include desktop {
          margin-left: -10px;
        }
      }
    }

    .case-study {
      h3 {
        @extend .h-m;
        padding-top: 0;
        margin-bottom: 15px;
      }
    }
  }

  .lesson-body-blocks > .rich-text:first-child {
    > p:first-child {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .step-by-step {
    h2,
    h3,
    h4 {
    }

    > ul {
      margin: 0;
    }

    > ul.list-bullet > li {
      position: static;
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    img {
      height: auto;
    }
  }

  .lesson-hero,
  .media-section {
    img,
    video {
      border-radius: 5px;
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }

    video {
      width: 100%;
    }

    .video-transcript {
      &__text-area {
        max-height: 200px;
        overflow-y: scroll;
      }
    }
  }

  .lesson-hero {
    margin: 20px 0 20px;

    @include tablet {
      margin: 0 0 30px 0;
    }
  }

  .objectives {

    @include tablet {
      padding-bottom: 0;
    }

    hr {
      height: 1px;
      border: 0;

      @include tablet {
        margin-bottom: 45px;
      }
    }
  }

  .fictional-example {
    h2,
    h3,
    h4 {
      margin-bottom: 12px;
    }

    .rich-text > p:first-of-type {
      margin-top: 0;
    }

    .rich-text *:last-child {
      margin-bottom: 0;
    }
  }

  .ita-quote {
    img {
      margin-top: 5px;
      width: 40px;

      @include tablet {
        margin-left: 30px;
      }
    }

    &__quote p {
      font-weight: 700;
    }

    &__author {
      margin-top: 12px;
    }

    blockquote {
      @include tablet {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .g-panel {
      padding-left: 0;

      &:before {
        display: none;
      }

      p {
        margin: 0;
      }

      @include tablet {
        padding-left: 30px;

        &:before {
          display: block;
        }

        p {
          margin-top: 18px;
          margin-bottom: 18px;
        }
      }
    }
  }

  blockquote {
    @extend .body-l;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
  }

  .case-study {
    position: relative;

    border-top: 10px solid $blue-deep-10;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      right: -15px;
      top: 0;
      bottom: 0;
      background: #eef2f6;
      z-index: 0;
    }

    @include tablet {
      &:before {
        left: -2000px;
        right: -2000px;
      }
    }

    &__content,
    &__open {
      position: relative;
    }

    &__content {
      display: block;

      @include tablet {
        display: flex;
        padding-top: 0;
      }
    }

    &__close {
      background: none;
      border: 0;
      cursor: pointer;
      font-size: 33px;
      position: absolute;
      top: 30px;
      right: 0;
      z-index: 1;

      @include tablet {
        right: -58px;
        top: 23px;
      }
    }

    &__company {
      margin-top: 20px;
    }

    &__media {
      display: none;

      @include tablet {
        display: block;
        position: relative;
      }

      img,
      video {
        display: block;
        height: auto;
        margin: 0 auto;
        width: 100%;
      }

      .block-image:nth-child(2),
      .block-video + .block-image {
        img {
          display: none;
          position: absolute;
          top: 0;
          right: calc(-100% - 30px);

          @include tablet {
            display: block;
          }
        }
      }

      .quote {
        i {
          position: relative;
          left: -40px;
          top: 25px;
        }

        p {
          margin: 0;
        }
      }

      .c-full {
        float: none;
        margin: 0;
      }
    }

    &__mobile {
      @include tablet {
        display: none;
      }

      .quote-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  .estimated-time {
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 22px;
    }

    strong {
      height: 24px;
      display: inline-block;
      position: relative;
      top: -2px;
    }
  }
}

.lesson-body-blocks > div > h2:first-of-type {
  padding-top: 15px;
  @include govuk-media-query($from: tablet) {
    padding-top: 20px;
  }
}


.lesson-body-blocks > div > h3:first-of-type {
  padding-top: 5px;
  @include govuk-media-query($from: tablet) {
    padding-top: 10px;
  }
}

.govuk-list--number>li {
  list-style-type: decimal
}

.lesson-body-blocks b {
  font-family: "Noto SemiBold", Arial, Helvetica, Verdana;
}
