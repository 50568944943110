@import '../colors/colors.scss';
@import '../fonts/main.scss';
@import '../sizes/sizes.scss';

@mixin float-clear {
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin flag-red-underline {
  &:after {
    background-color: #b00d23;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    display: block;
  }
}

a.domestic, a.international {
  @include flag-red-underline;
  color: $color-main-text;
}
