@import 'helpers/colours';
@import '../../../styles/colors/colors.scss';
@import '../../../styles/fonts/main.scss';

a.back-link {
  color: $color-gov-link;
  display: inline-flex;
  align-items: center;
text-decoration: none!important;
font-size: $font-size-p;

&--inverse {
    color: $white;

    &:visited {
      color: $white;
    }
    &:hover {
      color: $white;
    }
  }

  i {
    font-size: 24px;
  }
}


td i, td label.far{
  font-size: 24px;
}
