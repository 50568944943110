@import 'helpers/colours';
@import '../../../styles/colors/colors.scss';

.segmentation-modal {
  .radio-block {
    overflow: hidden;
    margin-left: -5px;
    padding-left: 5px; // so the focus outline doesn't get clipped
  }

  .multiple-choice.multiple-choice {
    padding: 1px 0 19px;
    label {
      line-height: 1em;
      font-weight: unset;
      padding-left: 35px;
    }
  }
}

.modal {
  &.format-medium {
    overflow-y: auto;
    overflow-x: none;
    max-height:100%;
    @include breakpoint(sm) {
      width: 630px;
      height: auto;
      overflow: visible;
    }
  }

  .dialog-close {
    font-size: 42px;
    min-height: 40px;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    color: $color-main-text;
  }

  .modal-button-bar {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @include breakpoint(md) {
      flex-flow: row nowrap;
      align-items: center;

      .progress-section {
        flex: 1 0;
      }

      .progress-bar {
        margin-right: 20px;
      }
    }
  }
}
