/* Shared styles across BAU and Magna pages */
@import '../../../styles/main.scss';

// Breakpoints
$mobile: 640px;
$tablet: 768px;

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}


.shared-tag {
  height: 28px;
  display: inline-block;
  border-radius: 4px;
  background-color: #787191;
  color: #fff;
  font-family: $main-text-font;
  ;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  padding: 0 13px;
}

.shared-learning-banner {
  background-color: $color-footer;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: $large-padding $x-large-padding;
  margin-bottom: 30px;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  .tag-label {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: $main-text-font;
    font-size: 16px;
    text-align: center;
    height: 28px;
    width: 55px;
    border-radius: 4px;
    background-color: #EB4F4D;
    font-weight: 600;
    margin-right: $large-padding;
    margin-bottom: 15px;

  }

  .content-container {
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-top: $standard-padding;
    }

    h3 {
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
      padding-bottom: 10px;
      color: $color-alternative-text
    }

    p {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      color: $color-alternative-text
    }
  }

  .cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;

    @media (max-width: 960px) {
      margin-left: 0;
      flex-direction: row;
      justify-content: end;
    }

    .cta {
      padding: 10px 40px;
      align-self: flex-end;
      justify-content: center;
      box-sizing: border-box;
      border: 2px solid #FFFFFF;
      border-radius: 20px;
      color: #FFFFFF;
      font-family: $main-text-font;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
    }
  }
}