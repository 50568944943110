
.wave-animation {
  span {
    display:inline-block;
    width:6px;
    height:6px;
    border-radius:50%;
    margin-right:4px;
    background: $blue-deep-40;
    animation: wave 1.0s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.9s;
    }

    &:nth-child(3) {
      animation-delay: -0.8s;
    }
    &:nth-child(4) {
      animation-delay: -0.7s;
    }
  }
}

@keyframes wave {
  0%, 80%, 100% {
    transform: initial;
  }

  40% {
    transform: translateY(-10px);
  }
}
