@import '../../styles/colors/colors.scss';

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='number'],
textarea,
textarea.form-control {
  border-radius: 0px;
  border: 2px solid $color-main-text;
  color: $color-main-text;
}
input[type='checkbox'].markascomplete_checkbox {
  background-color: $color-completed;
  &:before {
    border-color: #0B0C0C;
    margin-top: 2px;
  }
  &:after{
    top: 9px;
  }
}

label {
  color: $color-main-text;
}

.multiple-choice {
  label,
  label:before,
  label:after {
    color: $color-gov-link;
  }
}

.select__placeholder {
  min-height: 50px;

  i {
    font-size: 18px;
  }
}

input[type='text'] {
  color: $color-main-text;
}

.search-input {
  i {
    font-size: 18px;
  }
}

.form-label,
.form-hint {
  font-family: $main-text-font;
}

.form-hint {
  padding-top: .15em;
  padding-bottom: .35em;
  font-size: 18px;
}

input[type=checkbox].markascomplete_checkbox{
    background-color: $color-completed;
}



label{
    color: $color-main-text;
}

.multiple-choice{
    label, label:before, label:after{
        color: $color-gov-link;
    }
}

.select__placeholder {
    height: 50px;

    i {
        font-size: 18px;
    }
}

input[type=text]{
    color: $color-main-text;

}

.search-input{ i{
        font-size: 18px;
    }
}
input[type=checkbox].markascomplete_checkbox{
    background-color: $color-completed;
}



label{
    color: $color-main-text;
}

.multiple-choice{
    label, label:before, label:after{
        color: $color-gov-link;
    }
}

.select__placeholder {
    height: 50px;

    i {
        font-size: 18px;
    }
}

input[type=text]{
    color: $color-main-text;

}

.search-input{ i{
        font-size: 18px;
    }
}
