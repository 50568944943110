@import '../colors/colors.scss';

.tooltip__close {

  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  cursor: pointer;
  margin: -5px -5px 0 0;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 15px;
  background-color: $color-main-background;
  transition: background-color 300ms;

  &:hover {
    background-color: $color-alternative-background;
  }

  span[role='img'] {
     color: $color-gov-link
  }

  &:focus {
    outline: 6px solid $color-focus;
  }
}

.tooltip-title{
    font-size: 20px;
}
