@import '../../../styles/colors/colors.scss';
@import '../../../styles/fonts/main.scss';
.signup {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  background: #f5f5f6;

  &__logo {
    width: 148px;
    height: auto;

    @media (max-width: 768px) {
      max-width: 132px;
    }
  }

  .form-label {
    @extend .body-m;
    @extend .p-t-0;
    font-size: 18px;
    color: $color-main-text;
    font-family: $header-font;
  }

  .form-control {
    @extend .m-t-0;
  }

  &__form-panel {
    color: #4c436e;
    min-height: 100vh;
    padding: 40px 105px 0px 105px;
    background-color: #fff;
    width: 610px;
    flex-shrink: 0;
    z-index: 1;

    @include breakpoint(lg_lt) {
      padding: 30px;
      width: 40%;
    }

    @include breakpoint(md_lt) {
      padding: 20px;
      width: 50%;
    }

    @include breakpoint(sm_lt) {
      width: 100%;
      padding: 30px 30px;
    }
  }

  &__form {
    .h-s {
      margin-top: 15px;
    }

    .vertical-seperator {
      margin-top: 20px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        font-size: 19px;
        width: 20%;
        text-align: center;
        margin: 0;
      }

      hr {
        display: inline-block;
        height: 2px;
        margin: 0;
        vertical-align: middle;
        width: 40%;
      }
    }
  }

  &__info-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0;
    position: relative;

    @include breakpoint(sm_lt) {
      display: none;
    }

    img {
      position: absolute;
      bottom: 0;

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        bottom: -100px;
      }

      @media screen and (min-width: 1400px) {
        bottom: -300px;
      }
    }

    &__content {
      max-width: 650px;
      padding: 0 40px;
      z-index: 1;
      text-align: center;
    }

    &__heading {
      color: #222;
      font-style:normal;
      font-size: $font-size-h3;
      line-height: $h3-line-height;
      font-weight: $font-weight-semi-bold;
      text-align: left;
    }

    &__subheadings {
      margin-top: 30px;
      text-align: left;

      li {
        display: flex;
        align-items: top;
        margin-bottom: 45px;
        font-size: 28px;
        line-height: 32px;

        @include breakpoint(lg_lt) {
          font-size: 24px;
          line-height: 34px;
        }

        span.great-icon {
          margin-right: 15px;
          font-size: 1.5em!important;
        }
      }
    }

    .button {
      min-width: 230px;
    }
  }

  &__confirmation {
    .fa-info-circle {
      font-size: 40px;
    }

    .form-label {
      font-size: 19px;
    }
  }

}
