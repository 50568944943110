@import '../../../styles/colors/colors.scss';
@import '../../../styles/sizes/sizes.scss';
.section-list {
  &__coming {
    border-radius: 12px;
    left: 20px;
    position: absolute;
    top: 120px;
  }

  &__disabled {
    opacity: 0.6;
    position: relative;
  }

  &__item {
    border-radius: 4px;
  }

  &__item--is-complete {
    .section-list__image-container {
      &:before {
        position: absolute;
        display: block;
        content: attr(data-complete);
        font-size: 16px;
        line-height: 2.2;
        color: white;
        background-color: $color-completed;
        height: 35px;
        padding: 0 20px;
        margin-left: -10px;
        margin-top: 20px;
      }
    }
  }

  &__image-container {
    display: flex;
    flex-flow: column;

    img {
      border: {
        top-right-radius: 4px;
        top-left-radius: 4px;
      }
    }
  }

  &__link {
    width: 100%;
  }
}

.section-upload {
  border: {
    color: $colour-blue-deep-20;
    radius: 4px;
    style: dashed;
    width: 1px;
  }
  display: block;
}

.export-plan-dashboard {
  .flex-grid > div{
padding: 0 $standard-padding;
>div{
  margin-bottom: $large-padding;
}
  }
  .sub-header-nav {
    margin-left: 0;
  }
}

.export-plan-dashboard__container {
  /*
  NOTE: at site width + (30px gutter * 2), the container loses its padding,
  as it is set using margin instead of... padding. This is fine for most
  pages, but collapses when there is a sidebar.
  */
  width: 100%;
  @media (min-width: 1290px) {
    padding: 0 30px;
  }
}

.export-plan-dashboard__section {
  background-color: #eef2f6;
  @include breakpoint(md) {
    display: flex;
  }
}

.export-plan-dashboard__section--white {
  background-color: #fff !important;
}

.export-plan-dashboard__sidebar {
  @include breakpoint(md) {
    flex: 0 0 330px;
  }

  .section-upload {
    font-size: 16px;
  }
}

.media {
  display: flex;
  align-items: flex-start;

  &--reverse {
    flex-direction: row-reverse;
  }

  &--top {
    align-items: flex-start;
  }

  &--middle {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }
}

.media__figure {
  margin-right: 1em;
}

.media__body {
  flex: 1;
}

.progress-bar {
  &--white-track {
    background-color: white;
    border-radius: 0!important;
  }
  >span{
    min-width: 2%;
  }
}
