@import '../fonts/main.scss';
@import '../colors/colors.scss';
@import '../sizes/sizes.scss';

$login-image: url(/static/images/signin-image.png);
$signup-image: url(/static/images/signup-image.png);
$banner: url(/static/images/great-banner-large.png);
$ea-signup-image: url(/static/images/export-academy-lady-light-green.png);




#sign-up,
#login {
  h1 {
    font-family: $header-font;
    font-weight: $font-weight-semi-bold;
  }

  h3 {
    color: $color-main-text;
  }

  .hide-logo-below-1200 {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .signup__form-panel,
  .great-signup__form-panel {
    max-height: auto;
    overflow: auto;

    #get-in-touch {
      font-size: $font-size-p;
      color: $color-main-text;
    }

    .form-label {
      font-family: $header-font !important;
      font-size: $font-size-p;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: $h5-line-height;
    }

    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .great-signup__info-panel,
  .signup__info-panel {
    padding: 0;
    background-position: center 100% !important;
    background-repeat: no-repeat !important;
    position: relative;
    overflow-x: hidden;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .great-logo {
      position: absolute;
      height: 129px;
      width: 382px;
      background: $banner;
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 80px;
      right: -50px;

      @media only screen and (max-width: 1200px) {
        display:none;
      }
    }

    &.login-panel {
      background: $login-image;
      background-size: auto 45vh !important;

      &.hide_image_below_1200 {
        @media screen and (max-width: 1200px) {
          background: none;
        }
      }
    }

    &.signup-panel {
      background: $signup-image;
      background-size: auto 55vh !important;

      &.hide_image_below_1200 {
        @media screen and (max-width: 1200px) {
          background: none;
        }
      }
    }

    &.ea-login-panel {
      background: $ea-signup-image;
    }

    &.ea-signup-panel {
      background: $ea-signup-image;
      background-size: auto 50vh;
      padding-bottom: 300px !important;

      &.hide_image_below_1200 {
        @media screen and (max-width: 1200px) {
          background: none;
        }
      }
    }
  }

  .signup__info-panel__content {
    padding: 145px 40px 40vh 40px;
    height: 100%;
  }

  .signup__info-panel__subheadings {
    span.great-icon {
      color: #2397d4;
    }

    li {
      font-size: $font-size-h4;
      margin-bottom: $large-padding;
      font-weight: $font-weight-standard;
      line-height: $h5-line-height;
    }
  }
}
