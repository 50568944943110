@import '../colors/colors.scss';
@import '../fonts/main.scss';
@import '../sizes/sizes.scss';

.personalization-menu {
  position: absolute;
  box-shadow: rgb(0 0 0 / 25%) 0px 6px 10px 0px;
  padding: $large-padding;
  background-color: $color-main-background;
  z-index: 2;
  width: 375px;
  @media only screen and (min-width: $mobile-size) {
    margin-left: -13px;
  }

  ul {
    padding: 0;
    margin: 0;

    li + li {
      padding-top: 13px;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2px solid $color-background-color;
      padding-bottom: $standard-padding;

      a {
        flex: 1;
        font-family: $header-font;
        text-decoration: none;
        color: $color-main-text;
        &:hover {
          color: $color-gov-link;
        }
      }

      button {
        height: auto;
        background-color: transparent;

        .remove-product {
          height: auto;
          background-color: blue;

          i {
            color: $color-delete;
          }
        }
      }
    }
  }
}

#set-country-button {
  margin-left: $large-padding;
  @media only screen and (max-width: $mobile-size) {
    margin-left: 0;
  }
}

#set-product-button {
  margin-left: -13px;
}

  #set-country-button {
    margin-left: $large-padding;
    @media only screen and (max-width: 640px) {
      margin-left: 0;
    }
  }
