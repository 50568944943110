@import '../../../styles/colors/colors.scss';

.form {
  &--sign-up {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 150px;
    width: 430px;

    .vertical-separator {
      margin-top: 20px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        font-size: 19px;
        width: 20%;
        text-align: center;
        margin: 0;
      }

      hr {
        display: inline-block;
        height: 2px;
        margin: 0;
        vertical-align: middle;
        width: 40%;
      }
    }

    .beta {
      position: absolute;
      right: 30px;
      top: 25px;
    }

    .form-label {
      @extend .body-m;
      @extend .p-t-0;
      color: $black-100;
    }

    .form-control {
      @extend .m-t-0;
    }
  }
}

.form-table {
  .form-label {
    @extend .body-l-b;
  }

  .form-control {
    @extend .m-t-0;
  }

  .hr {
    @extend .m-0;
  }

  .select {
    margin-bottom: 20px;
  }

  .form-delete {
    display: flex;
    justify-content: center;
  }
}

.button--add {
  @include breakpoint(lg_lt) {
    width: 100%;
  }
}

.button--inherit {
  align-self: flex-start;
}

.great-mvp-error-list {
  li.error-message {
    text-align: left;
  }
}

.form-group--small {
  label {
    font-size: var(--font-size-body-3);
  }
}

.great-radio {
  display: flex;
  position: relative;

  &__input {
    opacity: 0;
    position: absolute;
  }

  &__label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;

    &::before {
      content: '';
      background: #fff;
      border: 2px solid $colour-blue-deep-60;
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      padding: 2px;
      margin-right: 20px;
      text-align: center;
    }
  }

  &__input + &__label::before {
    border-radius: 50%;
  }

  &__input:checked + &__label::before {
    background: $colour-blue-deep-60;
    box-shadow: inset 0px 0px 0px 9px #fff;
  }

  &__input:focus + &__label {
    outline: #ffd000 solid 6px;
  }
}

.great-radiogroup {
  display: flex;

  &__item {
    display: flex;
  }

  &__input {
    position: absolute;
    visibility: hidden;
  }

  &__label {
    cursor: pointer;
  }
}

.great-radiogroup--button {
  flex-flow: column nowrap;

  @include breakpoint(sm) {
    flex-flow: row nowrap;
  }

  .great-radiogroup__item {
    flex: 1 1 100%;

    .great-radiogroup__input:checked + .great-radiogroup__label {
      color: white;
      background-color: $color-primary-button;
    }
  }

  .great-radiogroup__item + .great-radiogroup__item .great-radiogroup__label {
    margin-top: 10px;

    @include breakpoint(sm) {
      flex-flow: row nowrap;
      margin-top: unset;
      margin-left: 10px;
    }
  }

  .great-radiogroup__label {
    color: $color-gov-link;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--button-small-font-size);
    font-weight: var(--button-font-weight);
    width: 100%;
    height: var(--button-small-height);
    border: 2px solid transparent;
    background-color: white;
    border: 2px solid $color-gov-link;
    padding: calc(2px + var(--button-padding-v)) 0 var(--button-padding-v);

    &:focus {
      outline: 6px solid $focus-yellow;
    }
  }
}

.great-radiogroup--temperature {
  .great-radiogroup__item {
    &:nth-child(1) .great-radiogroup__input:checked + .great-radiogroup__label {
      background-color: green;
    }
    &:nth-child(2) .great-radiogroup__input:checked + .great-radiogroup__label {
      background-color: lightgreen;
    }
    &:nth-child(3) .great-radiogroup__input:checked + .great-radiogroup__label {
      background-color: orange;
    }
    &:nth-child(4) .great-radiogroup__input:checked + .great-radiogroup__label {
      background-color: salmon;
    }
    &:nth-child(5) .great-radiogroup__input:checked + .great-radiogroup__label {
      background-color: red;
    }
  }

  .great-radiogroup__input:checked + .great-radiogroup__label {
    color: white;
  }
}

.form-group {
  input[type='number']:focus::placeholder {
    color: transparent;
  }
}

.inputgroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -$gutter-small;

  &__input {
    flex: 0 1 auto;
    margin-left: $gutter-small;

    &--month {
      min-width: 8.5em;
    }

    &--year {
      width: 8.5em;
    }

    &--medium {
      width: 8.5em;
    }
  }

  & .form-label {
    font-size: 1em;
    padding-top: 0;
  }

  & .form-group,
  & .select {
    margin-bottom: 0;
  }
}

// TODO: Remove once ported to great-styles
// For some odd reason there is a double selector for this in great-styles.
.select__list.select__list {
  display: block;
  max-height: 0;
  transition: max-height .25s ease-out;
}
.select__list.select__list[aria-expanded="true"] {
  max-height: 270px;
}
