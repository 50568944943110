.costs {
  --keyline: 2px solid #d2d0da;

  border-radius: 20px;
  display: block;
  padding: 20px;

  &--with-total {
    border-radius: 20px 20px 0 0;
  }

  &--total {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 20px 20px;

    .total {
      margin-left: auto;

      @include breakpoint(sm_lt) {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
      }
    }
  }
}

.costs__flex-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.costs__label {
  align-self: flex-start;
  margin-bottom: 10px;

  @include breakpoint(sm) {
    margin-bottom: 0;
    padding-top: 9px;
    padding-right: 10px;
  }

  & .tooltip {
    margin-top: 10px;
  }
}

.costs__input {
  align-self: flex-start;
  margin-left: auto;
  width: 100%;

  @include breakpoint(sm) {
    width: 50%;
    flex-shrink: 0;
  }

  & input {
    text-align: right;
  }
}

.costs__border {
  border-bottom: var(--keyline);
  padding-bottom: 20px;
}

.costs__option {
  & .button--delete {
    margin: 0;
  }
  &--border {
    &:focus {
      outline: none;
    }
  }
}

.costs__option--border:not(:first-child) {
  border-top: var(--keyline);
  padding-top: 20px;
  margin-top: 20px;
}

.costs__option .form-label {
  padding-top: 0;
  font-weight: 500;
}

.costs__delete {
  padding: 20px 0;
}

.gross-price {
  .select,
  .form-group {
    margin-bottom: 0;
  }
}
