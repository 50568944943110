@import 'helpers/colours';

.great-tour-modal {
  background: $white;
  padding: 35px 25px 0 35px;

  img {
    max-width: 100%;
  }
}

.great-tour-modal__footer {
  border-top: 1px solid $black-10;
  padding: 20px 35px;
}

.great-modal-text {
  margin-top: 16px;
  margin-bottom: 34px;
}

.great-tour-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.reactour__helper [data-tour-elem='controls'] {
  display: block;
  margin: 0;

  [data-tour-elem='navigation'] {
    justify-content: left;
    margin-bottom: 28px;
    margin-left: -3px;
  }

  [data-tour-elem='right-arrow'] {
    display: block;
    margin: 0;
  }

  [data-tour-elem='dot'] {
    background: #d2d0da;
    color: #d2d0da;

    &.reactour__dot--is-active {
      background: #4b426d;
      color: #4b426d;
      transform: scale(1);
    }
  }
}
