// GOVUK styles with Great styles
// All classes are prefixed with either
// .govuk- or .great-
@import 'styles/great/main.scss';

// Config
$asset-root: '/static/images/';
$fonts: '/static/fonts';
$fa-font-path: '/static/fonts';

// Icons
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

// Great styles
@import 'styles';

// Magna styles
@import 'helpers/utils';
@import 'helpers/mixins-cms';

@import 'components/forms';
@import 'components/progress-wizard';
@import 'components/insights';
@import 'components/product-finder';
@import 'components/compare-markets';
@import 'components/sign-up';
@import 'components/sign-up-old';
@import 'components/tour';
@import 'components/modal-cms';
@import 'components/spinner';
@import 'components/classification-tree';
@import 'components/slick';
@import 'components/segmentation';
@import 'components/backlink';
@import 'components/snackbar';

@import 'base';
@import 'overrides';

// Pages
@import 'exportplan/dashboard';
@import 'exportplan/statistics';
@import 'exportplan/sections';
@import 'learn/base';
@import 'exportplan/sidebar';
@import 'exportplan/costs';
@import 'exportplan/adaptation-target-market-form';
@import 'exportplan/animations';

// Shared styles
@import 'shared/base';

@import '../components/sass/components/cookie-notice/base';

// TO DO - remove the below styles when we use the Great Design System to style all buttons/links
.personalization-menu-button.tag--icon {
    border-bottom: 3px solid transparent;
}

.personalization-menu-button.tag--icon:focus,
.personalization-menu-button.tag--icon:active {
  background-color: #fd0;
  outline: none;
  border-bottom: 3px solid #222;
  color: #222;
}
