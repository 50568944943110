@import 'helpers/colours';
@import '../../../styles/sizes/sizes.scss';

.market-details {
  table {
    table-layout: fixed;
    max-width: none;
    width: auto;
    min-width: 100%;

    td {
      &.align-top {
        vertical-align: top;
      }
    }

    thead th {
      vertical-align: bottom;
    }
  }

  .table--width {
    width: 20%;
  }

  .tooltip__content {
    padding-right: 20px;

    p {
      margin: 20px 0;

      &:first-child,
      &:last-child {
        margin: 10px 0;
      }
    }
  }

  .vertical-align-top {
    vertical-align: top;
  }
}

table .loading {
  animation: shimmer 5s infinite linear;
  background: linear-gradient(to right, #e9e7ed 20%, #faf8fe 50%, #e9e7ed 66%);
  background-size: 1000px 100%;
  border-radius: 4px;
  height: 20px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.filter .filter-list li.multiple-choice {
  clear: none;
  margin-right: 25px;
  padding: 0 0 0 32px;
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

table.no-bottom-border {
  tr:last-child {
    td,
    th {
      border: none;
    }
  }
}

.w-full-mobile {
  @include breakpoint(md_lt) {
    width: 100%;
  }
}

.checkbox-favourite {
  opacity: 0;
  position: absolute;

  & + label {
    padding: 3px;
    cursor: pointer;

    &:before {
      content: '\f004';
    }
  }

  &:focus + label {
    outline: $focus-yellow solid 3px;
  }

  &:checked + label {
    font-weight: 900; // solid fa font
  }
}

.market-action-row {
  display: flex;
  align-items: center;
  .button {
    font-size: 20px;
    min-height: auto;
  }
  .country-name-wrapper {
    flex: 1;
  }
  margin: 0 20px;
  border-bottom: 1px solid #d2d0da;

  @media only screen and (max-width: 980px) {
    padding-top: $standard-padding;
    padding-bottom: $standard-padding;
  }
}
