@import '../fonts/main.scss';
@import '../colors/colors.scss';
@import '../sizes/sizes.scss';

$breakpoint: 1250px;

nav.main-nav ul a {
  font-family: $main-text-font !important ;
  font-size: $font-size-p;
  border-bottom: 0;
  text-decoration: none;
  color: $color-alternative-text !important;
  padding-right: 4px;
  border: none !important;
}

nav.breadcrumbs {
  padding: 0.75em $x-large-padding;
  max-width: $content-max-width;
  background-color: $color-background-color;
  @media only screen and (min-width: calc( $content-max-width + (2* $x-large-padding))) {
    margin: 0 auto;
    padding: 0.75em 0;
  }

  @media only screen and (max-width: calc( $content-max-width + (2* $x-large-padding))) {
    padding: 0.75em $x-large-padding;
  }

  a,
  span {
    font-size: 16px;
  }

  @media only screen and (max-width: $mobile-size) {
    a {
      font-size: 14px;
    }

    li:not(:first-child, :last-child) {
      display: none;
    }
  }

  li {
    margin-bottom: 0;
  }
}

nav {
  .magna-header__nav {
    @media only screen and (max-width: $breakpoint) {
      display: none;
    }
  }
}

.magna-header__dropdown {
  right: 0;
  margin-top: 10px;
  background-color: $color-footer !important;
  @media only screen and (max-width: $mobile-size) {
    margin-top: 9px;
  }
  .button,
  .button:visited,
  .button:hover,
  .button:active {
    background: $color-gov-link;
  }
}

nav.breadcrumbs {
  padding-top: calc(0.75em - (0.5 * $standard-padding)) !important;
  padding-bottom: calc(0.75em - (0.5 * $standard-padding)) !important;
  background-color: $color-background-color;

  background-color: $color-background-color;
  @media only screen and (max-width: $mobile-size) {
    padding: calc(0.75em - (0.5 * $standard-padding)) 15px !important;
  }

  li {
    padding: 0.5 * $standard-padding 0;
    margin-left: 0;
  }
  li::after {
    margin-right: $standard-padding;
    top: 0.4em;
  }

}

.back-breadcrumbs{
  li[aria-current="page"] {
    display: none;
  }
   li::after {
    display: none;
   }

   li::before {
    box-sizing: initial;
    transform: rotate(135deg);
    content: "";
    border-style: solid;
    display: inline-block;
    height: 5px;
    width: 5px;
    position: relative;
    vertical-align: top;
    top: 1px;
    border-color: #333;
    border-width: 0 2px 2px 0;
    margin: 2px 0 0 10px;
    top: 0.4em;
   }

   li a {
      margin-left: 10px;
   }
}

nav {
  .magna-header__nav {
    @media only screen and (max-width: $breakpoint) {
      display: none;
    }
  }
}

.magna-header__dropdown-button {
  font-family: $main-text-font;
  font-size: $font-size-p !important;

  z-index: 3;
}
