@import 'elements/colours';
@import '../../styles/colors/colors.scss';
label {
  display: block;
}

.text-center {
  text-align: center;
}

.great-mvp-grey-link {
  color: $grey;
  font-size: 16px;
}

.text-12 {
  font-size: 12px;
}

.text-16 {
  font-size: 16px;
}

.great-mvp-right-text {
  text-align: right;
}

.multiple-choice input {
  z-index: 0;
}

.great-mvp-small-checkbox-container {
  .form-group {
    margin-bottom: 10px;
  }

  .multiple-choice {
    min-height: 30px;
  }

  .multiple-choice input:disabled + label {
    opacity: 1;
    padding-left: 0;
  }

  .multiple-choice [type='checkbox'] + label::before,
  .multiple-choice input {
    height: 28px;
    width: 28px;
    box-sizing: border-box;
  }

  .multiple-choice input:checked + label::after {
    width: 14px;
    height: 6px;
    top: 6px;
    left: 4px;
  }
}

.section--with-sidebar {
  padding-left: 300px;
}

body {
  background-color: $color-background-color;
  overflow-x: hidden;

  &.body-background-white {
    background-color: $white;
  }

  #great-hero {
    background-color: $stone;
    padding-bottom: 75px;
    margin-bottom: -75px;

    .g-breadcrumbs ol li::after {
      border-color: $grey;
    }
  }
}

.br-xs {
  border-radius: 10px;
}

.button {
  &-linkedin {
    background-color: #0077b6;
  }

  &-google {
    background-color: #4385f4;
  }
}

.icon-large {
  font-size: 40px;
}

.no-scoll {
  overflow: hidden ;
}

.back-to-export-plan {

  @include desktop {
    display: table;
    bottom: 20px;
    position: sticky;
    top: 20px;
  }
}

.back-section {
  bottom: 0;
  position: sticky;

  @include breakpoint(sm) {
    position: relative;
  }
}

.lesson-body {
    display: flex;
}
