@import '../fonts/main.scss';
@import '../colors/colors.scss';
@import '../sizes/sizes.scss';

.select {
  font-size: $font-size-p;

  .select_chevron {
    margin-top: 3px;
    padding-right: $standard-padding;
    color: $color-quotes-icons;
  }
}

.select__placeholder {
  display: flex;
  align-items: center;
  padding-left: $standard-padding;
  padding-right: $standard-padding;
  color: $color-quotes-icons;
  border: 2px solid $color-quotes-icons;
}

.select__list.select__list {
  border-color: $color-quotes-icons;
}

.select__placeholder--value {
  padding-left: $standard-padding * 0.5;
}

.select__list:not(.select__list--open){
 display: none;
}
