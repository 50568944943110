.clickable-card {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  &:active:focus {
    background: transparent;
  }
}

.card-inner {
  @media only screen and (max-width: $mobile-size) {
    > .primary-button {
      margin-left: auto;
    }
  }

  > .primary-button {
    display: none;
  }
}
