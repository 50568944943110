.slick-slider {
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0;
        font-size: 0;
        padding: 5px;
        cursor: pointer;
        opacity: .25;

        &:hover, &:focus {
          opacity: 1;
        }
      }

      button:before {
        content: '';
        display: block;
        background-color: currentColor;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }

      &.slick-active button {
        opacity: 1;
      }

    }
  }

  .slick-list {
    margin: 0 -7px;
    top: 25px;

    & .slick-track {
      display: flex;

      & .slick-slide {
        height: inherit;

        & > div {
          padding: 0 10px;
          height: 100%;

          & > div {
            height: 100%;
          }
        }
      }
    }
  }
}

.product-finder {
  .slick-dots {
    top: 10px;
    bottom: unset;
  }
}

.case-study__mobile {
  ul.slick-dots {
    bottom: -20px;
    top: unset;

    li {
      padding-left: 0;
      margin-bottom: 0;

      &:before {
        background-color: transparent;
      }
    }
  }

  .slick-list {
    top: 0;

    .slick-track .slick-slide {
      margin-bottom: 20px;

      img {
        margin: -30px 0;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
