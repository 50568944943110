@import 'helpers/colours';

.ReactModal__Content.ReactModalCentreScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.ReactModal__Overlay.ReactModalCentreScreen {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0px;
  inset: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1000;

  @include breakpoint(sm_lt) {
    background-color: $white;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  overflow: auto;
}
