.section {
  &--intro {
    margin-bottom: 30px;
  }

  &--back-to {
    position: absolute;
    top: 40px;

    @include breakpoint(lg) {
      left: 110px;
    }
  }

  &--intro-image {
    bottom: -30px;
    position: relative;
    width: 100%;

    @include breakpoint(lg) {
      width: 679px;
    }
  }
}

.lesson__back {
  margin-top: 30px;

  @include breakpoint(lg) {
    float: right;
    width: 245px;

    &--bottom {
      margin-top: 315px;
    }
  }
}

.great-checkbox--section-complete {
  label {
    font-size: var(--font-size-title-2);

    @include breakpoint(sm) {
      font-size: var(--font-size-title-3);
    }
  }
}

.hide-show-content,
.hide-show-trigger {
  display: none;
}

.hide-show-trigger:checked~div .hide-show-content {
  display: block;
}

.hide-show-trigger~div>div>label>i {
  padding: 0;
}

.hide-show-trigger:checked~div>label#hide-show-button>span[role="img"] {
  transform: rotate(90deg);
  padding-right: 0;
}

.hero-image-container {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 15% auto;

  &::before {
    content: '';
    @include stretch;
    right: 3%;
  }

  .grid {
    position: relative;
    z-index: 1;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .lh {
    align-self: flex-start
  }

  .rh {
    margin-bottom: -5%;

    @include breakpoint(md_lt) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

.watermark-container {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 25% auto;
}

.g-card.min-height-0 {
  min-height: 0;
}
