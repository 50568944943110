@import '../colors/colors.scss';
@import '../sizes/sizes.scss';
@import '../fonts/main.scss';
@import '../great/main.scss';

$breakpoint-size: 900px;
$desktop: 1024px;

#learn-root {
  article {
    background-color: $color-main-background;
    margin-bottom: $x-large-padding;

    &:last-child {
      margin-bottom: $x-large-padding * 2;
    }

    > a {
      text-decoration: none;
      box-sizing: border-box;
      min-height: 232px;
      padding: $x-large-padding;
      padding-left: 70px;
      padding-right: $large-padding * 2;
      display: flex;
      flex-wrap: wrap;
      gap: $x-large-padding;
      width: 100%;

      @media only screen and (max-width: $breakpoint-size) {
        flex-direction: column;
      }

      @media only screen and (max-width: $mobile-size) {
        flex-direction: column;
        gap: $standard-padding;
      }

      > div {
        flex: 1;

        &.progess-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          @media only screen and (min-width: $breakpoint-size) {
            .learn__category-progress-container,
            span.button {
              max-width: 340px;
              width: 100%;
            }
          }

          .learn__category-progress-container,
          span.button {
            width: 100%;
          }

          .learn__category-progress-container {
            margin-top: $standard-padding;
            position: relative;
            top: 0;
          }
        }

        > p {
          margin-top: $standard-padding;
          margin-bottom: $large-padding;
        }
      }

      @media only screen and (max-width: $breakpoint-size) {
        padding: $large-padding;
        padding-top: 35px;
        padding-bottom: 35px;

        & > div {
          .progess-container {
            justify-content: center;
          }

          .learn__category-progress {
            width: auto !important;
          }

          span.button {
            margin-top: $standard-padding;
          }
        }
      }
    }
  }
}

.learn-back-button {
  position: absolute;
  top: 10px;
  left: 20px;
  color: $color-gov-link;
}

.lesson-page {
  background-color: $color-main-background;
}

.learn-to-export-card {
  padding-top: $x-large-padding * 2;
}

.duration-container {
  color: $color-gov-link;
  display: flex;

  i + span {
    padding-left: $standard-padding;
  }

  span + span {
    padding-left: $standard-padding * 0.5;
  }
}

.great-checkbox--large [type='checkbox'] + label::after {
  border-width: 0 0 6px 6px;
  width: 21px;
  height: 8px;
  top: 15px;
  left: 13px;
}

.learn__lesson-item-link {
  display: flex;
  gap: $large-padding;
  @media only screen and (max-width: $mobile-size) {
    flex-direction: column;
    align-items: flex-start;
    gap: $standard-padding;
    button {
      width: 100%;
    }
  }
}

.learn__topic-item-details {
  @media only screen and (max-width: $mobile-size) {
    h2,
    p {
      padding-bottom: $standard-padding;
    }
  }
}

.learning-link {
  font-family: $main-text-font;
  color: $color-main-text;
  font-size: $font-size-p;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  text-decoration: none;
  border: 0;
  background: none;
  padding: 0;
}

.progress-bar-text {
  color: $color-main-text;
  font-family: $header-font;
  font-size: $font-size-p;
  text-transform: lowercase;
  margin: 0;
}

.learn__topic-item-read-time {
  color: $color-gov-link;

  i {
    color: $color-gov-link;
  }
}

i.fas.fa-clock {
  color: $color-main-text;
}

.learn__category-entities {
  height: 41px;
  color: $color-gov-link;
  border-radius: 0;
  background-color: $color-background-color;
  display: flex;
  align-items: center;
  padding-left: $large-padding;
  padding-right: $large-padding;
  padding-top: 0;
  padding-bottom: 0;

  i {
    padding-right: $standard-padding;
  }
}

.learn__topic-item-details {
  @media only screen and (max-width: $mobile-size) {
    h2,
    p {
      padding-bottom: $standard-padding;
    }
  }
}

.media-block {
  margin-left: 0 !important;
  width: 100% !important;
}

.media-block > img,
.media-block > i {
  width: 40px !important;
}

.lesson-page {
  .great-quote {
    &:before {
      top: 0px !important;
    }
    & p {
      font-family: $header-font!important;
      font-size: 24px!important;
      margin: 0;
      display: inline!important;
      margin-left: 30px;
      line-height: 34px;
    }
  }
}

ol.list-circle-number li{
  font-family: $main-text-font!important;
  padding-top: 5px;
  &::before {
    font-family: $header-font!important;
    min-width: 40px;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    margin-top: -5px;
    font-size: 24px;
    line-height: 40px!important;
  }
  color: inherit
}

aside {
  h2 {
    padding-top: 10px;
  }

  ul {
    margin-bottom: 40px;
  }

  li {
    min-height: 40px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  li a {
    margin-left: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.great-ukea {
  .ea-logo {
    width: 118px
  }

  div {
    margin-top: 20px;
    border-top: 3px solid #964F99;
    padding-top: 15px;
  }

  a {
    display: flex;
    justify-content: space-between;

    &::after {
      content: "\f061";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      float: right;
    }
  }

  p {
    margin-top: 8px;
  }
}

.lesson-page {
  video {
    width: 100%;
  }
}

li .lte-suptopic {

  &:not(:last-child) {
    border-bottom: 1px solid #b1b4b6;
  }
  .great-card {
    box-shadow: none !important;
  }

  a {
    border-bottom: none!important;
    box-shadow: none !important;
    outline: none !important;
    padding: 0!important;
    > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.learn-card-description p {
  @extend .govuk-body
}

.related-links-cta-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;

  // horizontal scrolling of child elements
  @include govuk-media-query($until: desktop) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    margin: 0;
    width: 100vw;
  }

  @include govuk-media-query($from: desktop, $until: 1170px) {
    & {
      .great-card--cta{
        width: 33.33%;
      }
    }
  }

  a {
    display: flex;
    width: 230px;

    @include govuk-media-query($from: desktop, $until: 1170px) {
      width: unset;
    }
  }

  & {
    .great-card__tag {
      position: unset;
    }
  }
}

.related-links-cta-container__title-container {
  margin: 0px 20px 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.related-links-cta-container__tag-outer-container {
  display: flex !important;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  margin-bottom: 8px;
  margin-top: 10px;
}

.related-links-cta-container__tag-inner-container{
  display: flex;
  flex-direction: row;
  align-items: start;

  > img {
    @include govuk-media-query($until: tablet) {
      margin: 3px 8px 0px 0px !important;
    }

    @include govuk-media-query($from: tablet) {
      margin: 5.5px 8px 0px 0px !important;
    }
  }
}

.article-page-cta-container-title-container {
  margin: 0px 20px 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.article-page-cta-container-inner-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}
