.classification-tree {
  padding-left: 10px;
  .grid {
    padding: 10px 20px;
  .type-heading {
    font-weight: bold;
  }

    .level-description, .type-heading {
      padding: 0;
    }
  }
}
