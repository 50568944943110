.statistic {
  @extend .bg-white;
  @extend .radius;
  display: block;
  padding: 20px;
  width: 100%;

  @include breakpoint(sm) {
    margin-bottom: 30px;
  }

  .educational-moment--tooltip-container {
    margin-right: -8px;
    float: right;
  }

  &-label {
    border-radius: 13px;
    margin-right: 10px;
    padding: 5px 10px;
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    @include breakpoint(sm) {
      flex-direction: column;
      width: unset;
      border-bottom: unset;
      padding-bottom: unset;
    }
  }

  &__caption {
    font-size: $font-size-small-mobile;
    margin: 0;

    @include tablet {
      font-size: $font-size-small-desktop;
    }
  }

  &__figure {
    margin: 0;
    padding: 0;
    text-align: right;

    @include breakpoint(sm) {
      text-align: left;
    }
  }

  &--mobile-full {
    @include breakpoint(sm_lt) {
      dl {
        display: block;
      }
      .statistic__figure {
        text-align: left;
      }
    }
  }
}

.statistic__group {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-flow: wrap;

  & dl:last-of-type {
    text-align: right;
  }
}

.statistic__percentage {
  height: 4px;
  width: 100%;

  span {
    position: relative;
    display: block;
    height: 4px;
    transition: width 1s;

    &:after {
      content: '';
      position: absolute;
      right: -2px;
      top: 0;
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #fff;
    }
  }
}

.stat-group {
  // Vars
  --line-rule: 1px solid #e0e0e0;

  & > .grid,
  & > .grid > div {
    display: flex;
  }

  .grid {
    flex-flow: column;

    @include breakpoint(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @include breakpoint(sm_lt) {
    & .statistic {
      border-radius: 0;

      &:after {
        content: '';
        display: block;
        position: relative;
        top: 20px;
        width: 100%;
        border-bottom: var(--line-rule);
      }
    }

    & .grid > :first-child > .statistic:first-child {
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }

    & .grid > :last-child > .statistic:last-child {
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);

      &:after {
        content: none;
      }
    }
  }
}

.stat-group--cols {
  .statistic__group {
    flex-flow: row wrap;
  }

  dl {
    max-width: 49%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.stat-group--percentage {
  dl {
    & + dl {
      align-items: flex-end;
    }
  }
}

.selected-groups {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  &__item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @extend .body-m;
    background-color: $blue-deep-20;
    border-radius: 100px;
    padding: 2px 12px;
    margin: 5px;

    & button {
      color: #787191;
      min-height: 0;
      font-size: 24px;
      margin-left: -10px;
      margin-right: 5px;
    }
  }
}
