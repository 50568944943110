// DIT colour palette

// Red
$flag-red: #b00d23; // rgb(176,  13,  35)
$great-red: #e41f13; // rgb(228,  31,  19)
$great-red-alt: #ef5f56; // rgb(239,  95,  86)

// Grey
$light-grey: #d8d8d8; // rgb(216, 216, 216)
$mid-grey: #666666;
$grey: #333333; // rgb( 51,  51,  51)


// White
$offwhite: #fafafa; // rgb(220, 224, 223)
$white: #ffffff; // rgb(255, 255, 255)

// Blue
$active-blue: #006ccc; // rgb(0, 108, 204)
$active-blue-alt: #328dc2; // rgb(50, 141, 194)
$active-blue-disabled: #9bc6e9; // rgb(155, 198, 233)
$dark-blue: #132965; // rgb(19,  41, 101)
$dark-navy: #160d37; // rgb(22,  13,  55)
$great-blue: #1e1348; // rgb(30,  19,  72)
$lightened-great-blue: #3b3458;
$mid-blue: #00549f; // rgb(0,  84, 159)
$hero-blue: rgba(0,  82,  157,  0.8);
$aqua: #007ea3; // rgb(0, 126, 163)
$teal: #00a699;
$light-blue: #2b8cc4; // from govuk elements
$light-aqua: #8ae2fb; // rgb(138, 226, 251)

// Other
$black: #000000; // rgb(0,   0,   0)
$focus-yellow: #ffbf47; // rgb(255, 191,  71)
$purple: #4f0b7b; // rgb( 79,  11, 123)

// Stone
$stone: #dfd5c5; // rgb(223, 213, 197)
$stone-90: #e2d9cb;
$stone-80: #e5ddd1;
$stone-70: #e9e2d6;
$stone-60: #ece6dc;
$stone-50: #efeae2;
$stone-40: #f2eee8;
$stone-30: #f5f2ed; // rgb(245, 242, 237)
$stone-20: #fcfbf9;
$stone-10: #fdfdfc;

$stone-light:          #f5f2ed; // rgb(245, 242, 237)
$tap-highlight:       rgba(0, 0, 0, 0.3);
