.pill {
  border-radius: 1.2em;
  padding: 5px 15px;
  margin-right: 10px;
}

.hidden {
  display: none;
}

/* Use this when the content should be hidden only on JS enabled site */
.js-enabled .js-hidden {
  display: none;
}

$colours: ($black, 'black'), ($grey, 'grey'), ($mid-grey, 'mid-grey'),
  ($light-grey, 'light-grey'), ($offwhite, 'offwhite'), ($white, 'white'),
  (transparent, 'transparent'), ($purple, 'purple'), ($great-blue, 'great-blue'),
  ($dark-navy, 'dark-navy'), ($mid-blue, 'mid-blue'),
  ($active-blue, 'active-blue'), ($light-blue, 'light-blue'), ($aqua, 'aqua'),
  ($teal, 'teal'), ($flag-red, 'flag-red'), ($great-red, 'great-red'),
  ($focus-yellow, 'focus-yellow'), ($stone-10, 'stone-10'),
  ($stone-20, 'stone-20'), ($stone-30, 'stone-30'), ($stone-40, 'stone-40'),
  ($stone-50, 'stone-50'), ($stone-60, 'stone-60'), ($stone-70, 'stone-70'),
  ($stone-80, 'stone-80'), ($stone-90, 'stone-90'), ($stone, 'stone');

@each $colour, $name in $colours {
  .bg-hover-#{$name}:hover {
    background-color: $colour;
  }
}

@each $colour, $name in $colours {
  .text-hover-#{$name}:hover {
    color: $colour;
  }
}

@each $colour, $name in $colours {
  .border-hover-#{$name}:hover {
    border-color: $colour;
  }
}

.centre-children {
  text-align: center;

  > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.clearfix:after {
  display: block;
  content: '';
  clear: both;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.radius-xs {
  @include breakpoint(sm_lt) {
    border-radius: var(--radius);
  }
}

.radius-top-xs {
  @include breakpoint(sm_lt) {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }
}

.radius-bottom-xs {
  @include breakpoint(sm_lt) {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
}

$percentages: (
  10: 10,
  20: 20,
  30: 30,
  40: 40,
  50: 50,
  60: 60,
  70: 70,
  80: 80,
  90: 90,
  100: 100,
);

@each $pc, $name in $percentages {
  .max-width-#{$name} {
    max-width: $pc * 1%;
  }
}

.only-mobile {
  @include breakpoint(sm) {
    display: none !important;
  }
}

.only-desktop {
  @include breakpoint(sm_lt) {
    display: none !important;
  }
}

.w-0 {
  width: 0;
}
