@import '../colors/colors.scss';
@import '../sizes/sizes.scss';
@import '../fonts/main.scss';
@import '../sizes/sizes.scss';


#sectors-form {
  margin-top: $standard-padding;
}

.markets-grid {
  @media only screen and (min-width: $mobile-size) {
    display: table-cell;
  }
  .card-inner {
    padding: $large-padding;
    padding-bottom: $x-large-padding * 2;
    min-height: 105px !important;
    height: 105px;

    h2 {
      font-size: $font-size-h4;
    }
  }

  @media only screen and (max-width: $mobile-size) {
    .card-inner {
      min-height: fit-content !important;
      height: 80px;
      display: inline-flex;
      align-items: center;
      gap: $standard-padding;
    }

    .card:last-of-type {
      margin-bottom: 20px;
    }
  }
}

#country-guide-need-help-section,
#country-guide-teaser-section,
#country-guide-section-one,
#country-guide-accordions,
#country-trade-barriers,
#country-guide-accordions {
  background-color: $color-main-background;
}

#country-guide-teaser-section {
  .teaser>h1 {
    padding-bottom: $x-large-padding;

    @media only screen and (max-width: $mobile-size) {
      padding-bottom: $standard-padding;
    }
  }
}

#country-guide-intro-ctas {
  a {
    font-size: 18px;
    font-family: $main-text-font;
  }
}

.stats-button-container {
  display: flex;
  justify-content: flex-end;
}

#stats-tabs {
  h2 {
    @include p-mixin;
    margin-bottom: $standard-padding;
  }
}

#country-guide-need-help-section {
  padding: 40px 75px;
  @media only screen and (max-width: $mobile-size) {
    padding: 0;

    h2 {
      padding-left: $standard-padding * 1.5;
      padding-top: $standard-padding * 1.5;
    }

    > div {
      > div {
        max-width: $mobile-size;
        width: 100%;
        padding: $standard-padding * 1.5;

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  h2 {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: $x-large-padding;
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;

    > div {
      flex: 1;
      width: 100%;
      min-width: 300px;
      @media only screen and (min-width: 600px) {
        min-width: 350px;
      }

      h3 {
        color: $color-gov-link;
        padding-top: $x-large-padding;
        padding-bottom: $x-large-padding;
      }

      p {
        margin: 0;
        padding: 0;
        padding-bottom: $x-large-padding;
        font-size: 24px;
      }

      img {
        max-width: 500px;

        max-height: 185px;
        width: auto;
        @media only screen and (max-width: $mobile-size) {
          width: 100%;
          max-height: fit-content;
        }
      }
    }
  }
}

.fact-sheet-content {
  h3 {
    padding-bottom: $x-large-padding;
    font-size: 36px;
  }
}

.tabs__nav {
  border: none;
  ul {
    display: inline-flex;
    flex-wrap: wrap;
  }
  button {
    font-size: 28px;
    border: none !important;
    border-bottom: 7px solid #d6e0e5 !important;
    height: 60px;
    &:focus,
    &:hover,
    &[aria-expanded='true'] {
      border-bottom: 7px solid $color-gov-link !important;
    }
  }
}

.bold-large,
.bold-medium {
  font-family: $main-text-font;
}

.controls #sortby {
  @media only screen and (max-width: $mobile-size) {
    height: 50px;
  }
}

#country-guide-teaser-section p.sub-header {
  color: $color-subheading-text;
  margin-bottom: $standard-padding;

  @media only screen and (min-width: $mobile-size) {
    font-size: 24px;
  }
}

